<template>
  <div class="left-con">
      <div class="left-list-con">
        <div class="outer-class">
        <div v-for="(item,index) in articleListStore.articleList " :key="index">
          <ArticleItemText v-if="!item.cover" :item="item"></ArticleItemText>
          <ArticleItemCover v-if="item.cover" :item="item"></ArticleItemCover>
        </div>
        <div class="left-empty" v-if="articleListStore.total===0">
          <span class="left-empty-tips">无文档</span>
        </div>
      </div>
      </div>
<!--    <LeftConFoot></LeftConFoot>-->
  </div>
</template>

<script setup>
  import ArticleItemCover from "@/pages/co/listCo/articleItem/article_Item_cover.vue";
  import ArticleItemText from "@/pages/co/listCo/articleItem/article_Item_text.vue";
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();

</script>

<style scoped>
.left-con{
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: #45474f;
  overflow-y: scroll;
}
.outer-class{
  height:100%;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left-list-con{
  width: 100%;
  height: 100%;
  background: #444751;
  padding-top: 9px;
}
.left-empty{
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-empty-tips{
  color: rgba(255, 255, 255, 0.33);
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
</style>
