import COS from 'cos-js-sdk-v5';
const cos = new COS({
    SecretId: 'AKID0CTXMteMtAYzA5uzFFyZBXcScT4NR8H8',
    SecretKey: '8XopbDpGYwAIAdXO0KRtFLB5162mqfHc',
    FileParallelLimit:6,
});

const cherryBucket={
    bucket:"cheery-1304040337",
    region:"ap-shanghai",
    url:"cheery-1304040337.cos.ap-shanghai.myqcloud.com"
}
// wpg的路径为：userId/wpg/timestamp.wpg
// res的路径为：userId/res/timestamp_file
async function uploadRes(userId,file){
    return new Promise((resolve,reject)=>{
        let filename=new Date().getTime()+'_'+file.name;
        let key=userId+"/res/"+filename;
        cos.putObject({
            Bucket: cherryBucket.bucket, /* 填入您自己的存储桶，必须字段 */
            Region: cherryBucket.region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
            Key: key,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
                // console.log(JSON.stringify(progressData));
            }
        }, function(err, data) {
            if(err){
                reject(err);
            }else {
                resolve(data);
            }
        });
    });
}

async function uploadVideoCover(userId,file){
    return new Promise((resolve,reject)=>{
        let filename=new Date().getTime()+'_'+'cover.jpeg';
        let key=userId+"/res/"+filename;
        cos.putObject({
            Bucket: cherryBucket.bucket, /* 填入您自己的存储桶，必须字段 */
            Region: cherryBucket.region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
            Key: key,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
                // console.log(JSON.stringify(progressData));
            }
        }, function(err, data) {
            if(err){
                reject(err);
            }else {
                resolve(data);
            }
        });
    });
}

// 文章复制时复制对一个的wpgJson文件;
async  function copyWpgFile(userId,file){
    return new Promise((resolve,reject)=>{
        let key="wpg/"+userId+"/"+userId+"_"+Date.now()+".wpg";
        let src=cherryBucket.url+"/"+file;
        cos.putObjectCopy({
            Bucket: cherryBucket.bucket, /* 填入您自己的存储桶，必须字段 */
            Region: cherryBucket.region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
            Key: key,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
            CopySource: src, // 拷贝的文件底子
        }, function(err, data) {
            if(err){
                reject(err);
            }else {
                resolve(data);
            }
        });
    });

}

export{uploadRes,uploadVideoCover,copyWpgFile}
