import {createRouter, createWebHashHistory} from "vue-router";
import UserLogin from "@/pages/user_login.vue";
import ArticleList from "@/pages/article_list.vue";
import ArticleEditor from "@/pages/article_editor.vue";
import QuillEditor from "@/quill-editor/quill-editor.vue";
import SocialCallback from "@/pages/SocialCallback/index.vue";

const routes = [
    {
        path: "/",
        name: "login",
        component: UserLogin,
    },
    {
        path: "/list",
        name: "articleList",
        component: ArticleList,
    },
    {
        path: "/editor",
        name: "articleEditor",
        component: ArticleEditor,
    },
    {
        path: "/quill",
        name: "quillEditor",
        component: QuillEditor,
    },
    {
        path: "/social-callback",
        name: "socialCallback",
        component: SocialCallback,
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
