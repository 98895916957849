import Quill from "quill";
import "./editor.css";
import { ref } from "vue";

const Size = Quill.import("attributors/style/size");
Size.whitelist = [...new Array(32)].map((a, i) => i + "px");
Quill.register(Size, true);

export const ES_TEST_KEY = "ES_TEST_KEY";

export const ES = {
  // 多实例对象
  instance: {},
  // 当前选择对象
  activeId: ref(null),
  // 字号列表
  fontSizeList: [
    {
      text: "标题",
      fs: 22 + "px",
    },
    {
      text: "小标题",
      fs: 20 + "px",
    },
    {
      text: "副标题",
      fs: 18 + "px",
    },
    {
      text: "正文",
      fs: 16 + "px",
      default: true,
    },
    {
      text: "说明",
      fs: 12 + "px",
    },
  ],
  // 颜色列表
  colorList: [
    "#000000",
    "#78797C",
    "#E86B5D",
    "#EBB677",
    "#F1D168",
    "#7DCD70",
    "#598AF0",
    "#A973CF",
  ],
  // 获取当前实例
  getIns(key) {
    return this.instance[key];
  },
  // 初始化实例
  init(id, options) {
    const quill = (this.instance[id] = new Quill("#" + id, {
      ...options,
      modules: {
        history: {
          delay: 300,
          maxStack: 500,
          userOnly: true,
        },
      },
    }));

    quill.root.addEventListener("focus", () => {
      this.activeId.value = id;
      console.log("focus", this.activeId.value);
    });

    quill.root.addEventListener("blur", () => {
      quill.off("editor-change");
      quill.history.clear();
      this.activeId.value = null;
      console.log("blur", this.activeId.value);
    });
    return this.instance[id];
  },
  // 销毁实例
  destroy(key) {
    delete this.instance[key];
  },
  // 触发行为
  dispatch(name, value) {
    const ins = this.getIns(this.activeId.value);
    console.log("🚀 ~ file: editor.js:76 ~ dispatch ~ ins:", ins);
    if (ins) {
      const format = ins.getFormat();
      switch (name) {
        case "undo":
          ins.history.undo();
          break;
        case "redo":
          ins.history.redo();
          break;
        case "align":
          ins.format(name, value);
          break;
        case "color":
          ins.format(name, value);
          break;
        case "list":
          ins.format(name, value);
          break;
        case "size":
          ins.format(name, value);
          break;
        case "indent":
          ins.format(name, value);
          break;
        case "bold":
          ins.format(name, !format.bold);
          break;
        case "italic":
          ins.format(name, !format.italic);
          break;
        case "underline":
          ins.format(name, !format.underline);
          break;
      }
    }
  },
  // 默认样式
  getDefaultStyle(type) {
    if (type == "color") this.colorList[0];
    const defaultSize = this.fontSizeList.find((_) => _.default);
    return {
      color: this.colorList[0],
      size: defaultSize.fs,
    };
  },
  // 通过字号获取元素
  getFontSizeToItem(size) {
    const item = this.fontSizeList.find((_) => _.fs == size);
    if (item) return item;
    const defaultItem = this.fontSizeList.find((_) => _.default);
    return defaultItem;
  },
};
