<template>
  <div class="con">
    <div class="con-sub">
      <div class="img">
        <a-image fit="cover" :width="474" :src="props.item.lowUrl"></a-image>
      </div>
      <div class="tips-con">
        <span class="tips" :class="props.item.cls">
         {{props.item.tips}}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  })

  onMounted(() => { });

</script>

<style scoped>
.con{
  width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  padding: 0 24px;
  box-sizing: border-box;
}
.con-sub{
  width: 474px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.img{
  width: 474px;
  /*height: 245px;*/
  border-radius: 4px;
  overflow: hidden;
}
.tips-con{
  width: 474px;;
  margin-top: 14px;
}
.tips{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #828282;
}
</style>
