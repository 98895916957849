<template>
  <div class="left-con">
    <!-- <LeftHeader v-if="articleEditStore.curParts.length !== 0"></LeftHeader> -->
    <div outer-class="outer-class">
      <div v-for="(item, index) in articleEditStore.curParts" :key="index">
        <TextPart
          :idx="index"
          :index="index"
          v-if="item.tp === 'text'"
        ></TextPart>
        <ImagePart
          :idx="index"
          :index="index"
          v-if="item.tp === 'image'"
        ></ImagePart>
        <VideoPart
          :idx="index"
          :index="index"
          v-if="item.tp === 'video'"
        ></VideoPart>
      </div>
      <div class="left-empty" v-if="articleEditStore.curParts.length === 0">
        <span class="left-empty-tips">无段落</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import TextPart from "@/pages/co/editCo/part/text_part.vue";
import ImagePart from "@/pages/co/editCo/part/image_part.vue";
import VideoPart from "@/pages/co/editCo/part/video_part.vue";
import { useArticleEditStore } from "@/store/articleEdit";
import LeftHeader from "@/pages/co/editCo/left_con_header.vue";

const articleEditStore = useArticleEditStore();

onMounted(() => {});
</script>

<style scoped>
.left-con {
  width: 250px;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-top: 14px;
  background: #45474f;
  overflow-y: scroll;
}
/* 隐藏 left-con滚动条 */
.left-con::-webkit-scrollbar {
  display: none;
}
.outer-class {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left-list-con {
  width: 100%;
  height: 100%;
  background: #444751;
}
.left-empty {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-empty-tips {
  color: rgba(255, 255, 255, 0.33);
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}
</style>
