import VideoFrameCover from "video-frame-cover";
//////////////////////////////////////////////// 资源工具 ///////////////////////////////////////////////////
// 上传image


// 获取视频封面图
// https://www.npmjs.com/package/video-frame-cover
function getVideoCover(videoUrl,cb){
   let coverFrame=  new VideoFrameCover({
        url: videoUrl,
        currentTime:3,
        quality: 0.5,
        imageType: 'image/jpeg',
        isCheckBackgroundColor: true,
       success:(res)=>{
           let file=coverFrame.base64ToBlob(res);
           // console.log(file);
           cb(file)
       },
    })
}

//////////////////////////////////////////////// Wpg工具 ///////////////////////////////////////////////////
// 创建wog的base信息部分
// function createBase(){
//     return {
//         // 对应数据库ID
//         id:null,
//         // 对应数据库用户ID
//         uid:null,
//         // 对应数据库用户昵称
//         nick:null,
//         // 文章背景图的url地址
//         bg:null,
//         // 文章前景图地址
//         front:null,
//         // 背景音乐的url地址
//         music:null,
//         // 创建时间
//         createAt:null,
//         // 最后编辑时间
//         lastAt:null,
//         // 导出时间
//         exportAt:null,
//         // 标签组；与数据库同步
//         label:[],
//         // 文章模版样式，这里是模版样式必须是前端固定的组合---这是固定模版样式-A04
//         cls:null
//     };
// }
//
// // 创建wgp的header也就是title部分
// function createHeader(){
//     return {
//         // 这里是标题
//         title:null,
//         // 封面图的地址
//         cover:null
//     };
// }
function createArt(){
    return {
        // 这里是标题
        title:null,
        // 封面图的地址
        cover:null,
        // 背景图
        bg:null,
        // 前景图
        front:null,
        // 主题
        theme:null,
        // 背景音乐
        music:null,
    };
}
// 创建wpg的文本段落
function createTextPart(){
    return {
        // 文本段落示例
        tp : "text",
        // 文本段落内容
        content:null,
        // 这是用户编辑的文本样式；可以使用indexOf()来判断；使用str.replace("123","")来删除
        cls:""
    }
}

// 创建wpg的图片段落
function createImagePart(){
    return {
        // 图片段落示例
        tp : "image",
        // 图片地址
        highUrl:null,
        // 图片地址
        lowUrl:null,
        // 文本段落内容
        tips:null,
        // 这是用户编辑的提示文本样式
        cls:""
    }
}

// 创建wpp的视频段落
function createVideoPart(){
    return {
        // 视频段落示例
        tp : "video",
        // 视频地址
        url:null,
        // 封面地址
        cover:null,
        // 文本段落内容
        tips:null,
        // 这是用户编辑的提示文本样式
        cls:""
    }
}

export {
    // wpg 工具
    // createBase,
    // createHeader,

    createArt,
    createTextPart,
    createImagePart,
    createVideoPart,

    getVideoCover
}
