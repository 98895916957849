<template>
  <div
    class="con"
    :style="props.idx === 0 ? 'margin-top: 34px' : 'margin-top: 4px'"
  >
    <div style="width: 50%; margin: 0 auto" v-if="props.idx === 0">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '1' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('1')"
        >
          +
        </div>
      </a-divider>
    </div>
    <div
      class="sub"
      :class="articleEditStore.curPartId === props.idx ? 'active' : ''"
    >
      <QuillEditor
        placeholder="请输入内容..."
        :id="'ql-' + props.idx.toString()"
        :value="item['content']"
        @input="textInput"
        @blur="onBlur"
        @focus="onFocus"
      />
      <!-- <a-textarea
        :model-value="item['content']"
        @input="textInput"
        :autofocus="false"
        @blur="onBlur"
        @focus="onFocus"
        ref="textArea"
        :auto-size="true"
        :class="item.cls"
        class="text"
        placeholder="请输入内容"
      /> -->
    </div>
    <div style="width: 50%; margin: 0 auto">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '2' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('2')"
        >
          +
        </div>
      </a-divider>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useArticleEditStore } from "@/store/articleEdit";
import QuillEditor from "@/quill-editor/quill-editor.vue";

const articleEditStore = useArticleEditStore();
// const textArea = ref(null);
const props = defineProps({
  idx: Number,
});

const item = computed(() => {
  return articleEditStore.curParts[props.idx];
});
const checkedAdd = (type) => {
  articleEditStore.handlePart(props.idx + type);
};
const textInput = (value) => {
  let part = articleEditStore.curParts[props.idx];
  console.log(
    "🚀 ~ file: text_edit.vue:74 ~ textInput ~ props.idx:",
    props.idx
  );
  part.content = value;
};
const onFocus = () => {
  articleEditStore.showFootMenu = true;
  articleEditStore.selectPart(props.idx);
  //let part=articleEditStore.curParts[props.idx];
};
const onBlur = () => {
  //articleEditStore.showFootMenu=false;
  let part = articleEditStore.curParts[props.idx];
  if (part.content == null || part.content.trim().length === 0) {
    articleEditStore.delPart(props.idx);
  }
};
</script>

<style scoped>
.sub {
  width: 690px;
  padding: 0 90px;
  box-sizing: border-box;
}
.active {
  position: relative;
  background: rgba(244, 181, 67, 0.15);
  padding-top: 15px;
  padding-bottom: 15px;
}
.active::after {
  content: "";
  position: absolute;
  left: 4px;
  width: 4px;
  height: calc(100% - 8px);
  top: 0;
  bottom: 0;
  margin: 4px 0;
  background: linear-gradient(0deg, #f4b543 0%, #f4b543 100%), #5ea2ff;
  border-radius: 20px;
}
.text {
  color: #222222;
  font-weight: 400;
}
:deep(.text .arco-textarea) {
  color: rgba(0, 0, 0, 0.66);
  font-size: 15px;
  font-weight: 400;
}

.add-icon {
  width: 24px;
  color: #d7d7d7;
  font-weight: 800;
  margin: 0 auto;
}

.add-icon:hover {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-radius: 8px;
}
.checked-add-box {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 800;
  background: #eab859;
  border: 1px solid #fff;
  border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
