<template>
  <router-view />
</template>

<script setup>
 import {onMounted} from "vue";
 import {Message} from "@arco-design/web-vue";

 onMounted(()=>{
     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
     if (flag){
         Message.error("不支持手机端，请于电脑端使用该编辑器")
     }
 });
</script>
<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100vh;
  overflow-y:hidden;
}
</style>
