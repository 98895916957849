<template>
  <div class="con" :style="props.idx === 0?'margin-top: 34px':'margin-top: 4px'">
    <div style="width: 50%; margin: 0 auto" v-if="props.idx === 0">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '1' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('1')"
        >
          +
        </div>
      </a-divider>
    </div>
    <div
      class="sub"
      :class="articleEditStore.curPartId === props.idx ? 'active' : ''"
    >
      <div class="top">
        <div class="image-row">
          <div class="image-con">
            <a-image
              :height="135"
              fit="scale-down"
              :src="item.lowUrl"
            ></a-image>
            <div class="left-col" @click.stop="delPart">
              <img
                src="@/assets/article/close.png"
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="right-col">
              <a-upload
                action="/"
                :multiple="false"
                :auto-upload="false"
                ref="uploadRef"
                accept=".jpg, .jpeg, .png"
                :show-file-list="false"
                :show-remove-button="false"
                :show-retry-button="false"
                :show-cancel-button="false"
                :on-before-upload="uploadImage"
              >
                <template #upload-button>
                  <img
                    src="@/assets/article/switch.png"
                    style="width: 100%; height: 100%"
                  />
                </template>
              </a-upload>
            </div>
          </div>
        </div>
        <div
          class="btn-row"
          @click.stop="showInput"
          v-if="!tipsShow && !item.tips"
        >
          添加说明
        </div>
        <a-textarea
          v-if="tipsShow || item.tips"
          style="width:auto;color: rgba(0, 0, 0, 0.5); font-size: 12px; margin-top: 10px"
          :model-value="item.tips"
          @blur="hideInput"
          @focus="onfocus"
          ref="tips"
          @input="textInput"
          auto-size
          :class="item.cls"
        />
      </div>
    </div>
    <div style="width: 50%; margin: 0 auto">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '2' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('2')"
        >
          +
        </div>
      </a-divider>
    </div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title> 警告 </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
  </div>
</template>

<script setup>
import { defineProps, nextTick, ref, computed } from "vue";
import { useArticleEditStore } from "@/store/articleEdit";
import { Message } from "@arco-design/web-vue";
import { uploadRes } from "@/utils/cos";
import { onMounted } from "vue";

const articleEditStore = useArticleEditStore();
const props = defineProps({
  idx: Number,
});
const tips = ref(null);
const tipsShow = ref(false);
const isShow = ref(false);
const item = computed(() => {
  return articleEditStore.curParts[props.idx];
});
if (item.value.tips != null && item.value.tips.trim().length > 0) {
  tipsShow.value = true;
}
onMounted(() => {
  if(tipsShow.value){
    setTextWidth(item.value.tips);
  }
});
const textInput = (value) => {
  setTextWidth(value);
  let part = articleEditStore.curParts[props.idx];
  part.tips = value;
};
const setTextWidth =(value)=>{
  // 获取value的width长度，赋值给textarea
  if(value){
    let div = document.createElement("div");
    div.style.width = "auto";
    div.style.display = "inline-block";
    div.innerHTML = value;
    document.body.appendChild(div);
    let width = div.offsetWidth>500?500:div.offsetWidth;
    document.body.removeChild(div);
    let textarea = tips.value.$el.querySelector("textarea");
    textarea.style.width = width + "px";
  }
}

const delOK = () => {
  isShow.value = false;
  articleEditStore.delPart(props.idx);
};
const checkedAdd = (type) => {
  articleEditStore.handlePart(props.idx + type);
};
// 功能按钮
const delPart = () => {
  isShow.value = true;
};
const delCancel = () => {
  isShow.value = false;
};
const uploadImage = async (file) => {
  articleEditStore.loading = true;
  if (file.size >= 1024 * 1024) {
    Message.error("图片文件最大上传为1MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("资源上传成功");
    let url = "https://" + rst.Location;
    articleEditStore.switchImage(props.idx, url);
  } else {
    Message.error("资源上传错误");
  }
  articleEditStore.loading = false;
};
const showInput = () => {
  tipsShow.value = true;
  articleEditStore.selectPart(props.idx);
  nextTick(() => {
    tips.value.focus();
  });
};
const onfocus = () => {
  articleEditStore.selectPart(props.idx);
  articleEditStore.showFootMenu = true;
};
const hideInput = () => {
  let part = articleEditStore.curParts[props.idx];
  if (part.tips === null || part.tips.trim().length === 0) {
    part.cls = "";
    tipsShow.value = false;
  }
};
</script>

<style scoped>
.con {
  margin-top: 4px;
}
.sub {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 64px;
  width: 630px;
  box-sizing: border-box;
}
.active {
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 60px;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #f4b543 0%, #f4b543 100%), #5ea2ff;
  border-radius: 20px;
}
.top {
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.image-row {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 135px;
  border-radius: 8px;
  background: rgba(24, 37, 70, 0.1);
}
.image-con {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: relative;
}
.left-col {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  left: -30px;
}
.right-col {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  right: -30px;
}
.btn-row {
  width: 64px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.33);
  font-size: 10px;
  font-weight: 400;
  margin-top: 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
}
.btn {
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #4f4f4f;
}
.input-row {
  margin-top: 10px;
  width: 520px;
  text-align: center;
  color: #828282;
  font-weight: bold;
}
:deep(.input-row .arco-textarea) {
  font-size: 15px;
  line-height: 21px;
  text-align: inherit;
  font-style: inherit;
  font-weight: inherit;
}
:deep(textarea) {
  min-width: 64px;
  min-height: 22px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.33);
  font-size: 10px;
  font-weight: 400;
  text-align: center !important;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.arco-textarea-wrapper:focus-within, .arco-textarea-wrapper.arco-textarea-focus{
  border: 0;
}

textarea:focus {
  border-color: #eab859;
}
textarea:focus-visible {
  outline: #eab859 auto 1px;
}
.arco-textarea-wrapper {
  border: none !important;
}
.add-icon {
  width: 24px;
  color: #d7d7d7;
  font-weight: 800;
  margin: 0 auto;
}

.add-icon:hover {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-radius: 8px;
}
.checked-add-box {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 800;
  background: #eab859;
  border: 1px solid #fff;
  border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
