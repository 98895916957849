<template>
  <div class="con">
    <div class="con-sub">
      <div class="video">
        <video ref="videoRef"
               playsinline
               class="video-js vjs-big-play-button  vjs-big-play-centered" >
        </video>
      </div>
      <div class="tips-con">
        <span class="tips" :class="props.item.cls">
         {{props.item.tips}}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import videojs from "video.js";
  import "video.js/dist/video-js.css"
  import {defineProps, defineEmits, onMounted, ref, onUnmounted, computed} from 'vue'
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  })
  const videoRef=ref(null)
  const myPlayer = ref(null)
  const options=computed(()=>{
    return {
      controls: false,
      poster:props.item.cover,
      autoplay:false,
      preload:"auto",

      width:"474px",
      height:"245px",
      sources: [
        {
          src: props.item.url,
          type: 'video/mp4',
        }
      ],
      controlBar: {
        fullscreenToggle: true,//显示全屏按钮，默认为true
        pictureInPictureToggle: false,//隐藏画中画按钮，默认为true
        volumePanel: false,//隐藏声音面板
        currentTimeDisplay: true,//显示当前播放时间
        timeDivider: true,//显示时间分割线
        durationDisplay: true,//显示总时间
        remainingTimeDisplay: false,//隐藏剩余时间，
      },
    }
  })
  onMounted(() => {

    myPlayer.value = videojs(videoRef.value,options.value , () => {
      // videojs.log('播放器已经准备好了!');
    });

  });
  onUnmounted(() => {
    if (myPlayer.value) {
      myPlayer.value.dispose()
    }
  })

</script>

<style scoped>
.con{
  width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  padding: 0 24px;
  box-sizing: border-box;
}
.con-sub{
  width: 474px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.video{
  width: 474px;
  height: 245px;
  border-radius: 8px;
  overflow: hidden;
}
.tips-con{
  width: 474px;;
  margin-top: 14px;
}
.tips{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #828282;
}

</style>
