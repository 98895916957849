<template>
  <div class="con">
    <div class="uncover">
      <a-textarea
        :model-value="articleEditStore.curArt.title"
        placeholder="请输入文章标题"
        auto-size
        class="uncover-text"
        style="text-align: center"
        @input="titleInput"
      />
      <div class="uncover-btn-row">
        <a-upload
          action="/"
          :multiple="false"
          :limit="1"
          :auto-upload="false"
          ref="selectCoverRef"
          accept=".jpg, .jpeg, .png"
          :show-file-list="false"
          :show-remove-button="false"
          :show-retry-button="false"
          :show-cancel-button="false"
          :on-before-upload="imageUpload"
        >
          <template #upload-button>
            <div v-if="articleEditStore.curArt.cover">
              <a-popover
                position="lt"
                :arrow-style="{ visibility: 'hidden' }"
                :content-style="{
                  padding: '0',
                  margin: '0',
                  left: '-50px',
                  top: '40px',
                }"
              >
                <div class="cover-image">
                  <a-image
                    :preview="false"
                    :width="64"
                    :height="28"
                    fit="cover"
                    :src="articleEditStore.curArt.cover"
                  ></a-image>
                </div>
                <template #content>
                  <div class="pop-con">
                    <a-upload
                      action="/"
                      :multiple="false"
                      class="pop-top pointer"
                      :limit="1"
                      :file-list="fileList"
                      :auto-upload="false"
                      ref="switchCoverRef"
                      accept=".jpg, .jpeg, .png"
                      :show-file-list="false"
                      :show-remove-button="false"
                      :show-retry-button="false"
                      :show-cancel-button="false"
                      :on-before-upload="selectCover"
                    >
                      <template #upload-button>
                        <div
                          style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)"
                        >
                          <span>更换</span>
                        </div>
                      </template>
                    </a-upload>
                    <div class="pop-down pointer" @click.stop="delCover">
                      <span>删除</span>
                    </div>
                  </div>
                </template>
              </a-popover>
            </div>
            <div v-else class="uncover-btn pointer">添加封面</div>
          </template>
        </a-upload>
        <div style="margin: 0 44px">
          <input
            :model-value="articleEditStore.curArt.author"
            placeholder="默认用户名"
            class="uncover-btn"
            style="width: 96px"
            @input="authorInput"
          />
        </div>
        <a-popover
          position="rt"
          :arrow-style="{ visibility: 'hidden' }"
          :content-style="{
            padding: '0',
            margin: '0',
            right: '-50px',
            top: '40px',
          }"
        >
          <div class="uncover-btn" style="cursor: pointer">
            {{
              articleEditStore.curArt.time
                ? articleEditStore.curArt.time
                : "文章创建时间"
            }}
          </div>
          <template #content>
            <div class="pop-con" style="font-size: 10px; color: #333">
              <div class="time-text" @click.stop="changeTime('newDate')">
                默认
              </div>
              <a-date-picker @change="changeTime">
                <div class="pop-down" style="color: #4f4f4f">更改</div>
              </a-date-picker>
            </div>
          </template>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useArticleEditStore } from "@/store/articleEdit";
import { uploadRes } from "@/utils/cos";
import { Message } from "@arco-design/web-vue";
import dayjs from "dayjs";

const articleEditStore = useArticleEditStore();
const fileList = ref([]);
onMounted(() => {});

const imageUpload = async (file) => {
  if (file.size >= 1024 * 1024) {
    Message.error("图片文件最大上传为1MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("资源上传成功");
    let coverUrl = "https://" + rst.Location;
    articleEditStore.uploadCover(coverUrl);
  } else {
    Message.error("资源上传错误");
  }
};

const titleInput = (value) => {
  articleEditStore.titleEdit(value);
};
const authorInput = (value) => {
  articleEditStore.authorEdit(value);
};
const changeTime = (value) => {
  let _date =
    value == "newDate"
      ? dayjs(new Date()).format("YYYY-MM-DD").toString()
      : value;
  articleEditStore.timeEdit(_date);
};
const selectCover = async (file) => {
  articleEditStore.loading = true;
  if (file.size >= 1024 * 1024) {
    Message.error("图片文件最大上传为1MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("资源上传成功");
    let coverUrl = "https://" + rst.Location;
    articleEditStore.uploadCover(coverUrl);
  } else {
    Message.error("资源上传错误");
  }
  articleEditStore.loading = false;
};

const delCover = () => {
  articleEditStore.delCover();
};
</script>

<style scoped lang="less">
.con {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 520px;
  margin-top: 32px;
  background-color: #ffffff;
}
.pop-con {
  width: 64px;
  height: 54px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}
.pop-top {
  width: 100%;
  height: 27px;
  line-height: 26px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #333;
}
.pop-down {
  width: 64px;
  height: 27px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #eb5757;
  line-height: 26px;
}
.uncover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 520px;
  background: #ffffff;
}
.cover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 520px;
  padding: 15px;
  background: #ffffff;
  box-sizing: border-box;
}
.uncover-text {
  width: 500px;
  max-height: 114px;
  color: rgba(0, 0, 0, 0.66);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
:deep(.uncover-text .arco-textarea) {
  text-align: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.66);
  font-size: 24px;
  font-weight: 500;
}
.uncover-btn-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.uncover-btn {
  height: 24px;
  color: rgba(0, 0, 0, 0.33);
  font-size: 10px;
  font-weight: 400;
  box-sizing: border-box;
  padding: 6px 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0;
}
input:focus {
  border-color: #eab859;
}
input:valid {
  border-color: #eab859;
}
input:invalid {
  border-color: #eab859;
}
input:focus-visible {
  outline: #eab859 auto 1px;
}
.arco-textarea-wrapper:focus-within, .arco-textarea-wrapper.arco-textarea-focus{
  border-color: #eab859;
}
textarea:focus {
  border-color: #eab859;
}
textarea:focus-visible {
  outline: #eab859 auto 1px;
}
.cover-image {
  width: 68px;
  height: 28px;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}
.cover-title {
  width: 378px;
  height: 70px;
  margin-left: 12px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  color: #333333;
}

.time-text {
  width: 44px;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
  padding-bottom: 3px;
}
.one-line {
  max-width: 102px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep(.cover-title .arco-textarea) {
  text-align: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 20px;
  line-height: 24px;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.33);
}
</style>
