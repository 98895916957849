<template>
  <div :id="props.id" ref="refTarget" class="ql-custom-box"></div>
</template>

<script setup>
import {
  onMounted,
  onUnmounted,
  defineProps,
  defineEmits,
  watch,
  ref,
} from "vue";
import { ES } from "./editor";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  value: String,
  placeholder: String,
});

const refTarget = ref();

let focus =false;

const emits = defineEmits(["input", "focus", "blur"]);

watch(ES.activeId, (id) => {
  if (id == props.id) {
    const quill = ES.getIns(id);
    if (quill) {
      quill.on("editor-change", () => {
        emits("input", quill.root.innerHTML);
      });
    }
  }
});

watch(
  () =>props.value,
  (val) => {
    if (props.id) {
      if(focus) return;
      const quill = ES.getIns(props.id);
      if (quill) {
        quill.root.innerHTML = val;
      }
    }
  },
);


onMounted(() => {
  if (props.id) {
    const quill = ES.init(props.id, {
      // placeholder: props.placeholder,
    });

    setTimeout(() => {
      quill.root.innerHTML = props.value || "";
    })

    // emits("input", quill.root.innerHTML);

    quill.root.addEventListener("focus", (e) => {
      focus = true;
      emits("focus", e);
      refTarget.value.style.borderColor = "transparent";
    });

    quill.root.addEventListener("blur", (e) => {
      focus = false;
      emits("focus", e);
      refTarget.value.style.borderColor = "transparent";
    });
  }
});

onUnmounted(() => {
  props.id && ES.destroy(props.id);
});
</script>

<style scoped>
.ql-custom-box {
  padding: 8px 4px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
}
</style>
