<template>
  <div class="right-con">
    <!-- <div class="bg-box"><div class="bg-inner"></div></div> -->
    <div class="right-list-con" style="position: relative">
      <div class="outer-class">
        <div class="right-list-display-con">
          <TitleEdit></TitleEdit>
          <div v-for="(item, index) in articleEditStore.curParts" :key="index">
            <TextEdit v-if="item.tp === 'text'" :idx="index"></TextEdit>
            <ImageEdit v-if="item.tp === 'image'" :idx="index"></ImageEdit>
            <VideoEdit v-if="item.tp === 'video'" :idx="index"></VideoEdit>
          </div>
        </div>
        <ToolBar />
      </div>
    </div>
    <RightFoot></RightFoot>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import RightFoot from "@/quill-editor/tool-bar.vue";
import TitleEdit from "@/pages/co/editCo/edit/title_edit.vue";
import TextEdit from "@/pages/co/editCo/edit/text_edit.vue";
import ImageEdit from "@/pages/co/editCo/edit/image_edit.vue";
import VideoEdit from "@/pages/co/editCo/edit/video_edit.vue";
import { useArticleEditStore } from "@/store/articleEdit";
import ToolBar from "@/pages/co/editCo/edit/tool_bar.vue";

const articleEditStore = useArticleEditStore();

onMounted(() => {});
</script>

<style scoped>
.right-con {
  width: 700px;
  height: 100%;
  margin-left: 37px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow-x: hidden;
}
.outer-class {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
}
/* 给right-list-con上加高120px的背景色 */
.right-list-con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.bg-box {
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

.bg-inner {
  height: 120px;
  margin-top: -120px;
  background: linear-gradient(
    0deg,
    rgba(245, 244, 245, 0.66) 34.9%,
    rgba(245, 244, 245, 0) 100%
  );
}
.arco-scrollbar-track-direction-vertical {
  top: 0;
  right: 0;
  box-sizing: border-box;
  width: inherit !important;
  height: 100%;
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  width: 650px;
  box-sizing: border-box;
}
.right-list-display-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
}
</style>
