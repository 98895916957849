<template>
  <div class="right-footer-tools">
    <div class="option-btns">
      <div style="display: flex;">
        <div class="btn-style pointer" @click.stop="delBtn">
          <img src="@/assets/article/delete.png" class="btn-icon"/>
          <span style="color: #EF544D;">删除</span>
        </div>
        <div class="btn-style pointer" @click.stop="copyBtn">
          <img src="@/assets/article/copy.png" class="btn-icon"/>
          <span>复制</span>
        </div>
        <div class="btn-style pointer" @click.stop="pdfBtn">
          <img src="@/assets/article/download.png" class="btn-icon"/>
          <span>下载</span>
        </div>
      </div>
      <div class="edit-btn pointer" @click.stop="editBtn">
        <img class="edit-img" src="@/assets/article/edit.png" alt="" srcset="">
        编辑</div>
    </div>
  </div>
</template>

<script setup>
  import {  defineProps,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
  import {Message} from "@arco-design/web-vue";
  const emit=defineEmits(['onShowModal']);
  const articleListStore=useArticleListStore();
  onMounted(() => {  });
  // 编辑按钮
  const editBtn = () => {
    articleListStore.editArticle();
  }
  // 删除按钮
  const delBtn = () => {
    if(articleListStore.curArticle!=null){
      emit("onShowModal");
    }else{
      Message.error("尚未选择相关的文章")
    }

  }
  // 拷贝阿牛
  const copyBtn = async() => {
   await articleListStore.copyArticle();
  }
  // 下载pdf按钮
  const pdfBtn = () => {
    articleListStore.downPdf();
  }
</script>

<style scoped>
.right-footer-tools{
  width: 700px;
  height: 120px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(245, 244, 245, 0.66) 34.9%, rgba(245, 244, 245, 0.00) 100%);
  z-index: 10;
}
.option-btns{
  width: 656px;
  height: 28px;
  display: flex;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 74px 16px 10px 16px;
}
.del-icon{
  width: 24px;
  height: 24px;
  margin-left: 0;
  color: #EF544D;
}
.other-icon{
  width: 24px;
  height: 24px;
  margin-left: 24px;
  color: #333333;
}
.edit-btn{
  height: 28px;
  box-sizing: border-box;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  background: #F4B543;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.edit-img{
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.edit-btn-text{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}
.btn-style{
  height: 28px;
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  font-weight: 400;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 7px 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #F4B543;
  margin-right: 10px;
}
.btn-icon{
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
</style>
