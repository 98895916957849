/**
 * Arco官方组件：https://arco.design/vue/component/avatar
 * Pinia官方文档：https://pinia.web3doc.top/
 * 组件传参：https://www.cnblogs.com/ylj2021/p/16261726.html
 * */

import { createApp } from "vue";
import App from "./App.vue";
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import router from "./router";
import "@arco-design/web-vue/dist/arco.css";
import piniaPluginPersist from 'pinia-plugin-persist'
import { createPinia } from 'pinia'
//  引入全局样式
import "@/global.css"
import "@/v2.css"

const app = createApp(App);
app.use(ArcoVue);
app.use(router);
app.use(ArcoVueIcon);
const pinia = createPinia()
pinia.use(piniaPluginPersist)
app.use(pinia);
app.mount("#app");
