<template>
  <div class="con ql-container ">
    <div class="text ql-editor"  v-html="item['content']"></div>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  })

  onMounted(() => {  });


</script>

<style scoped>
.con{
  width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  box-sizing: border-box;
}


</style>

