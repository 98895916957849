<template>
  <div class="con pointer" @click.stop="itemClick">
    <div
      :class="
        props.idx + '1' == articleEditStore.checkedAddIndex
          ? 'checked-add-box'
          : 'add-icon pointer'
      "
      v-if="props.index === 0"
      @click="checkedAdd('1')"
    >
      <span class="add-i">+</span>
    </div>
    <div
      class="top"
      :class="articleEditStore.curPartId === props.idx ? 'active' : ''"
    >
      <div class="sub-center">
        <div class="center-image">
          <img :src="item.cover" class="text-icon" />
        </div>
        <div class="sub-center-text two-line" :class="item.cls">
          {{ item.tips }}
        </div>
      </div>
      <div v-if="articleEditStore.curPartId === props.idx">
        <div class="up-btn pointer">
          <a-image
            v-if="props.idx > 0"
            :preview="false"
            :width="12"
            :height="6"
            src="/arrow_down.png"
            fit="cover"
            @click.stop="upBtn"
          ></a-image>
          <a-image
            v-if="props.idx < articleEditStore.curParts.length - 1"
            :preview="false"
            :width="12"
            :height="6"
            src="/arrow_top.png"
            fit="cover"
            @click.stop="downBtn"
          ></a-image>
        </div>
        <a-popover
          position="rt"
          :arrow-style="{ visibility: 'hidden' }"
          :content-style="{
            padding: '0',
            margin: '0',
            left: '-50px',
            top: '40px',
          }"
        >
          <div class="option-btn pointer">
            <span class="i-box"></span>
            <span class="i-box" style="margin: 0 2px"></span>
            <span class="i-box"></span>
          </div>
          <template #content>
            <div style="width: 64px; height: 54px">
              <div class="copy-btn" @click.stop="copyBtn">复制</div>
              <div class="delete-btn" @click.stop="delBtn">删除</div>
            </div>
          </template>
        </a-popover>
      </div>
    </div>
    <div
      :class="
        props.idx + '2' == articleEditStore.checkedAddIndex
          ? 'checked-add-box'
          : 'add-icon pointer'
      "
      @click="checkedAdd('2')"
    >
      <span class="add-i">+</span>
    </div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title> 警告 </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import { useArticleEditStore } from "@/store/articleEdit";

const articleEditStore = useArticleEditStore();

const props = defineProps({
  index: Number,
  idx: Number,
});
const isShow = ref(false);
const item = computed(() => {
  return articleEditStore.curParts[props.idx];
});

// 功能按钮
const delOK = () => {
  isShow.value = false;
  articleEditStore.delPart(props.idx);
};
// 功能按钮
const delBtn = () => {
  isShow.value = true;
};
const delCancel = () => {
  isShow.value = false;
};
const upBtn = () => {
  articleEditStore.upPart(props.idx);
};
const downBtn = () => {
  articleEditStore.downPart(props.idx);
};
const copyBtn = () => {
  articleEditStore.copyPart(props.idx);
};

// item选中
const itemClick = () => {
  articleEditStore.selectPart(props.idx);
};
const checkedAdd = (type) => {
  articleEditStore.handlePart(props.idx + type);
};
</script>

<style scoped>
.con {
  margin-top: 9px;
}
.copy-btn {
  line-height: 27px;
  color: #333;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.top {
  width: 182px;
  height: 50px;
  padding: 0 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #fff;
  border: 3px solid rgba(255, 255, 255, 0.33);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 9px auto;
  position: relative;
  border: 3px solid transparent;
  transition: all 0.1s;
}
.top:hover {
  border: 3px solid #81848a;
  transform: scale(1.2);
}
.active {
  transform: scale(1.2);
  border: 3px solid #81848a;
}
.active .sub-center-text {
  margin-bottom: 0;
}
.active::after {
  content: "";
  position: absolute;
  right: -13px;
  width: 4px;
  height: 100%;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #f4b543 0%, #f4b543 100%), #5ea2ff;
  border-radius: 20px;
}
.delete-btn {
  line-height: 27px;
  color: #eb5757;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
}
.option-btn {
  width: 24px;
  height: 24px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  margin: auto;
}
.i-box {
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #a2a2a2;
}
.close-btn {
  position: absolute;
  top: 4px;
  left: 3px;
  width: 16px;
  height: 16px;
}
.up-btn {
  width: 16px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 7px;
}
.pop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  width: 64px;
  height: 32px;
  position: relative;
}
.pop-text {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}
.sub-center {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
}

.sub-center-text {
  width: 94px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.66);
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}
.two-line {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
}
.center-image {
  width: 64px;
  height: 48px;
  box-sizing: border-box;
  padding: 4px 0;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
}
.center-text-con {
  width: 200px;
  height: 70px;
  margin-left: 8px;
}
.center-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #828282;
}

.three-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
}

.add-icon {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: rgba(242, 242, 242, 0.33);
  margin: 14px auto;
}
.add-icon:hover {
  width: 24px;
  height: 16px;
  color: rgba(255, 255, 255, 0.66);
  font-weight: 800;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.66);
  background: rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
.add-i {
  display: none;
}
.add-icon:hover .add-i {
  display: block;
}
.checked-add-box {
  width: 24px;
  height: 16px;
  color: #fff;
  font-weight: 800;
  background: #eab859;
  border: 1px solid #fff;
  border-radius: 8px;
  margin: 0 auto;
}
.checked-add-box .add-i {
  display: block;
}
.text-icon {
  width: 100%;
  height: 100%;
}
</style>
