/*
 * 加密解密模块；如果包体过大,请引用lib中的文件。
 * AES加密解密；采用cbc模式；pkcs7填充。
 * 参考文档：https://blog.csdn.net/Umbrella_Um/article/details/99686988
 * 官方文档：https://github.com/brix/crypto-js
 */

// eslint-disable-next-line import/extensions
import * as CryptoJS from "crypto-js";
//  aes解密
const AesCnf={
	key:'3dd19414e91ac01b',
	iv:'2624b9a9c447e587',
}
// key 和 iv 可以一致
const key = CryptoJS.enc.Utf8.parse(AesCnf.key);
const iv = CryptoJS.enc.Utf8.parse(AesCnf.iv);
//加密
function AesEncode(originStr) {
	const _cipher = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(originStr), key, {
		iv:iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	return _cipher.toString();
}
//解密
function AesDecode(cipherStr) {
	// let decryptStr = cipherStr.toString().replace(/\r\n/g,"").replace(/\n/g,"");
	let decrypt = CryptoJS.AES.decrypt(cipherStr, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
}
// base64加密
function Base64Encode(originStr) {
	let str = CryptoJS.enc.Utf8.parse(originStr);
	return CryptoJS.enc.Base64.stringify(str)
}
// base64解密
function Base64Decode(base64Str) {
	let str = CryptoJS.enc.Base64.parse(base64Str);
	return str.toString(CryptoJS.enc.Utf8)
}
// md5 加密
function Md5Encode(originStr) {
	return CryptoJS.MD5(signStr).toString()
}

// 短uuid
let unique=0;
function UUIDShort(prefix = '') {
	const time = Date.now();
	const random = Math.floor(Math.random() * 1000000000);
	unique++;
	return prefix + '_' + random + unique + String(time);
}
// 长uuid
let hexList=[];
for(let i=0;i<16;i++){
	hexList[i]=i.toString(16);
}
function UUIDLong() {
	let uuid = '';
	for (let i = 1; i <= 36; i++) {
		if (i === 9 || i === 14 || i === 19 || i === 24) {
			uuid += '-';
		} else if (i === 15) {
			uuid += 4;
		} else if (i === 20) {
			uuid += hexList[(Math.random() * 4) | 8];
		} else {
			uuid += hexList[(Math.random() * 16) | 0];
		}
	}
	return uuid.replace(/-/g, '');
}

export{
	AesEncode,
	AesDecode,
	Base64Encode,
	Base64Decode,
	Md5Encode,
	UUIDShort,
	UUIDLong
}
