<template>
  <div class="con" :style="props.idx === 0?'margin-top: 34px':'margin-top: 4px'">
    <div style="width: 50%; margin: 0 auto" v-if="props.idx === 0">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '1' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('1')"
        >
          +
        </div>
      </a-divider>
    </div>
    <div
      class="sub"
      :class="articleEditStore.curPartId === props.idx ? 'active' : ''"
    >
      <div class="top">
        <div class="image-row">
          <div class="image-con">
            <div class="video">
              <video
                style="height: 135px; border-radius: 8px"
                ref="videoRef"
                playsinline
                class="video-js vjs-big-play-button vjs-16-9 vjs-big-play-centered"
              ></video>
            </div>
            <div class="left-col" @click.stop="delBtn">
              <img
                src="@/assets/article/close.png"
                style="width: 100%; height: 100%"
              />
            </div>

            <div class="right-col">
              <a-upload
                action="/"
                :multiple="false"
                :auto-upload="false"
                ref="uploadRef"
                accept=""
                :show-file-list="false"
                :show-remove-button="false"
                :show-retry-button="false"
                :show-cancel-button="false"
                :on-before-upload="uploadVideo"
              >
                <template #upload-button>
                  <img
                    src="@/assets/article/switch.png"
                    style="width: 100%; height: 100%"
                  />
                </template>
              </a-upload>
            </div>
          </div>
        </div>

        <div
          class="btn-row"
          @click.stop="showInput"
          v-if="!tipsShow && !item.tips"
        >
          添加说明
        </div>
        <a-textarea
          v-if="tipsShow || item.tips"
          style="color: rgba(0, 0, 0, 0.5); font-size: 13px; margin-top: 10px"
          :model-value="item.tips"
          @blur="hideInput"
          @focus="onfocus"
          ref="tips"
          @input="textInput"
          auto-size
          :class="item.cls"
        />
      </div>
    </div>
    <div style="width: 50%; margin: 0 auto">
      <a-divider orientation="center">
        <div
          :class="
            props.idx + '2' == articleEditStore.checkedAddIndex
              ? 'checked-add-box'
              : 'add-icon pointer'
          "
          @click="checkedAdd('2')"
        >
          +
        </div>
      </a-divider>
    </div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title> 警告 </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
  </div>
</template>

<script setup>
import {
  defineProps,
  onMounted,
  ref,
  onUnmounted,
  computed,
  nextTick,
} from "vue";
import { useArticleEditStore } from "@/store/articleEdit";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { Message } from "@arco-design/web-vue";
import { uploadRes, uploadVideoCover } from "@/utils/cos";
import { getVideoCover } from "@/utils/partTools";

const articleEditStore = useArticleEditStore();
const props = defineProps({
  idx: Number,
});
const tips = ref(null);
let tipsShow = ref(false);
const isShow = ref(false);
const item = computed(() => {
  return articleEditStore.curParts[props.idx];
});
if (item.value.tips != null) {
  tipsShow.value = true;
}

const videoRef = ref(null);
const myPlayer = ref(null);
const options = computed(() => {
  let data = articleEditStore.curParts[props.idx];
  return {
    controls: false,
    poster: data.cover,
    autoplay: false,
    preload: "auto",
    aspectRatio: "4:3",
    width: "270px",
    src: data.url,
    controlBar: {
      fullscreenToggle: true, //显示全屏按钮，默认为true
      pictureInPictureToggle: false, //隐藏画中画按钮，默认为true
      volumePanel: false, //隐藏声音面板
      currentTimeDisplay: true, //显示当前播放时间
      timeDivider: true, //显示时间分割线
      durationDisplay: true, //显示总时间
      remainingTimeDisplay: false, //隐藏剩余时间，
    },
  };
});

onMounted(() => {
  myPlayer.value = videojs(videoRef.value, options.value, () => {
    myPlayer.value.poster(item.value.cover);
    myPlayer.value.src(item.value.url);
  });
});
onUnmounted(() => {
  if (myPlayer.value) {
    myPlayer.value.dispose();
  }
});

const textInput = (value) => {
  let part = articleEditStore.curParts[props.idx];
  part.tips = value;
};
const uploadVideo = async (file) => {
  articleEditStore.loading = true;
  if (file.size >= 20 * 1024 * 1024) {
    Message.error("视频文件最大上传为20MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("视频上传成功");
    let mp4Url = "https://" + rst.Location;
    getVideoCover(mp4Url, (file) => {
      birthVideoCover(mp4Url, file);
    });
  } else {
    Message.error("视频上传错误");
  }
  articleEditStore.loading = false;
};
const birthVideoCover = async (mp4Url, file) => {
  let rst = await uploadVideoCover(1, file);
  if (rst.statusCode === 200) {
    Message.success("封面上传成功");
    let coverUrl = "https://" + rst.Location;
    articleEditStore.switchVideo(props.idx, mp4Url, coverUrl);
    myPlayer.value.poster(item.value.cover);
    myPlayer.value.src(item.value.url);
  } else {
    Message.error("封面上传错误");
  }
};
const delOK = () => {
  isShow.value = false;
  articleEditStore.delPart(props.idx);
};
// 功能按钮
const delBtn = () => {
  isShow.value = true;
};
const delCancel = () => {
  isShow.value = false;
};

const showInput = () => {
  tipsShow.value = true;
  articleEditStore.selectPart(props.idx);
  nextTick(() => {
    tips.value.focus();
  });
};
const checkedAdd = (type) => {
  articleEditStore.handlePart(props.idx + type);
};
const onfocus = () => {
  articleEditStore.selectPart(props.idx);
  articleEditStore.showFootMenu = true;
};

const hideInput = () => {
  let part = articleEditStore.curParts[props.idx];
  if (part.tips === null || part.tips.trim().length === 0) {
    part.cls = "";
    tipsShow.value = false;
  }
};
</script>

<style scoped>
.con {
  margin-top: 4px;
}
.sub {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 64px;
  width: 630px;
  box-sizing: border-box;
}

.active {
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 60px;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #f4b543 0%, #f4b543 100%), #5ea2ff;
  border-radius: 20px;
}
.top {
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.image-row {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 135px;
  border-radius: 8px;
  background: rgba(24, 37, 70, 0.1);
}
.image-con {
  width: 200px;
  height: 135px;
  max-height: 100%;
  margin: auto;
  position: relative;
}
.left-col {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  left: 2px;
}
.right-col {
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.btn-row {
  width: 64px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.33);
  font-size: 10px;
  font-weight: 400;
  margin-top: 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
}
.btn {
  width: 100px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #4f4f4f;
}
.input-row {
  margin-top: 10px;
  width: 520px;
  text-align: center;
  color: #828282;
}
:deep(.input-row .arco-textarea) {
  font-weight: inherit;
  font-size: 15px;
  line-height: 21px;
  text-align: inherit;
  font-style: inherit;
}
:deep(.globalLeft textarea) {
  text-align: center !important;
}
.add-icon {
  width: 24px;
  color: #d7d7d7;
  font-weight: 800;
  margin: 0 auto;
}

.add-icon:hover {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.66);
  border-radius: 8px;
}
.checked-add-box {
  width: 24px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 800;
  background: #eab859;
  border: 1px solid #fff;
  border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
