import {defineStore} from "pinia";
import {api} from "@/utils/api";
import {Message} from "@arco-design/web-vue";
import {useArticleEditStore} from "@/store/articleEdit";
import router from "@/router/index";
import {useUserStore} from "@/store/user";
import {createArt} from "@/utils/partTools";

export const useArticleListStore = defineStore("articleList", {
    persist: {
        enabled: true, //开启存储
        storage: localStorage,
    },
    state: () => {
        return {
            // 页面加载状态
            loading: false,
            artLoading: false, // 文章加载状态
            // 个人文章总数
            total: 0,
            // 个人文章列表
            curArticle: null,
            articleList: [],
            // 当前文章详情
            curArt: null,
            curParts: [],
        };
    },
    getters: {},
    actions: {
        // 初始化获得个人文章总数和列表
        async init() {
            try {
                const userStore = useUserStore();
                if (userStore.id == null) {
                    await router.push({name: "login"});
                }
                this.total = null;
                this.articleList = [];
                let rst = await api()
                    .url("/article/list")
                    .post({userId: userStore.id});
                this.total = rst.data.total;
                this.articleList = rst.data.list;
            } catch (e) {
                Message.error("数据请求错误,请稍后重试~");
            }
        },

        // 获得文章详情-解析wpg格式
        async curDetail(item) {
            try {
                this.artLoading = true;
                this.curArticle = null;
                this.curArt = null;
                this.curParts = [];
                this.curArticle = item;
                let rst = await api().wpg("/v1/article").post({Url: item.file});
                if (rst.code !== "ok") {
                    Message.error("获取文章数据失败，请稍后重试");
                } else {
                    let data = JSON.parse(rst.data.data);
                    this.curArt = data.art;
                    this.curParts = data.parts;
                }
                this.artLoading = false;
            } catch (e) {
                this.artLoading = false;
                Message.error("数据请求错误,请稍后重试~");
            }
        },

        // 删除文章
        async delArticle() {
            try {
                this.loading = true;
                let rst = await api()
                    .url("/article/del")
                    .post({articleId: this.curArticle.id});
                let idx = -1;
                for (let i = 0; i < this.articleList.length; i++) {
                    if (this.curArticle.id === this.articleList[i].id) {
                        idx = i;
                        break;
                    }
                }
                // let idx=this.articleList.indexOf(this.curArticle);
                if (idx > -1) {
                    this.articleList.splice(idx, 1);
                }
                this.curArticle = null;
                this.curBase = null;
                this.curHeader = null;
                this.curParts = [];

                this.loading = false;
                Message.success("该文章已成功删除");
            } catch (e) {
                this.loading = false;
                Message.error("数据请求错误,请稍后重试~");
            }
        },

        // 下载pdf
        async downPdf() {
            const user = useUserStore();
            try {
                this.loading = true;
                let params = {
                    nick: user.nick,
                    createAt: this.curArticle.createAt,
                    art: this.curArt,
                    parts: this.curParts,
                };
                let rst = await api().pdf("/pdf/cherry").post(params);
                if (rst.code !== "ok") {
                    Message.error("数据请求错误,请稍后重试~");
                } else {
                    let pdfUrl = rst.data.url;
                    window.open(pdfUrl, "_blank");
                }
            } catch (e) {
                Message.error("数据请求错误,请稍后重试~");
            } finally {
                this.loading = false;
            }
        },

        // 新建文章
        addNewArticle() {
            const articleEditStore = useArticleEditStore();
            articleEditStore.curType = "new";
            // 以下是数据处理
            articleEditStore.curArt = createArt();
            articleEditStore.curParts = [];
            articleEditStore.curArticle = null;
            // 以下是推送
            router.push({name: "articleEditor"});
        },

        // 拷贝文章 - 这里直接上传wpg和写入数据库；文章的标题需要修改一下。
        async copyArticle() {
            try {
                this.curArt.title += " 2";
                // 上传wpg
                let data = {
                    art: this.curArt,
                    parts: this.curParts,
                };
                const userStore = useUserStore();
                let rst = await api()
                    .wpg("/v1/upload")
                    .post({userId: userStore.id, data: JSON.stringify(data)});
                if (rst.code !== "ok") {
                    Message.error("Wpg文件上传失败，请稍后重试");
                } else {
                    let wpgUrl = rst.data.url;
                    let params = {
                        userId: userStore.id,
                        file: wpgUrl,
                        title: this.curArt.title,
                        cover: this.curArt.cover,
                        ver: 1.0,
                    };
                    let wpgRst = await api().url("/article/index").post(params);
                    console.log(wpgRst);
                    if (wpgRst.code !== "ok") {
                        Message.error("数据入库失败");
                    } else {
                        Message.success("数据已成功入库");
                        await this.init();
                        this.curArticle = this.articleList[0];
                    }
                }
            } catch (e) {
                Message.error("网络请求失败，请稍后重试");
            }
        },

        // 编辑当前文章
        editArticle() {
            const articleEditStore = useArticleEditStore();
            articleEditStore.curType = "edit";
            // 以下是数据处理
            articleEditStore.curArt = this.curArt;
            articleEditStore.curParts = this.curParts;
            articleEditStore.curArticle = this.curArticle;
            // 以下是推送
            router.push({name: "articleEditor"});
        },
    },
});
