/**
 * https://pinia.web3doc.top/
 * */
import {defineStore} from 'pinia'
import md5 from "js-md5";
import {api} from "@/utils/api";
import {Message} from "@arco-design/web-vue";

import {useRoute, useRouter} from "vue-router";
import router from "@/router/index";

export const useUserStore = defineStore('user', {
    persist: {
        enabled: true, //开启存储
        storage: localStorage,

    },
    state: () => {
        return {
            // 所有这些属性都将自动推断其类型
            id: null,
            nick: null,
            avatar: null,
            phone: true,
            // 暂未使用
            token: null,
            imageCode: null,
            imageCodeFile: null,
            smsCode: null,
            regReq: {
                regPhone: null,
                smsCode: null,
                regPwd: null,
                repeatPwd: null,
            },
            loginReq: {
                loginPhone: null,
                loginPwd: null,
                imageCode: null
            }
        }
    },

    actions: {
        // 用户登录
        async userLogin() {
            try {
                if (md5(this.loginReq.imageCode) !== this.imageCode) {
                    Message.error("图形验证码错误");
                    return;
                }
                let rst = await api().url("/login").post({
                    loginPhone: this.loginReq.loginPhone,
                    loginPwd: md5(this.loginReq.loginPwd),
                    imageCode: this.loginReq.imageCode
                });
                let data = rst.data;
                if (!data.state) {
                    Message.error("登录失败")
                } else {
                    let user = data.user;
                    this.id = user.id;
                    this.nick = user.nick;
                    this.avatar = user.avatar;
                    this.phone = user.phone;
                    this.token = data.token;
                    Message.success("成功登录");
                    router.push({name: 'articleList'});
                }
            } catch (e) {
                Message.error("系统错误,请稍后重试");
            } finally {
                await this.getImgCode();
            }
        },
        // 刷新验证码
        async getImgCode() {
            try {
                let rst = await api().url("/imageCode").post({});
                this.imageCodeFile = "data:image/png;base64," + rst.data.image;
                this.imageCode = rst.data.code;
            } catch (e) {
                Message.error("系统错误,请稍后重试");
            }
        },
        // 提交注册
        async userReg() {
            try {
                let md5Sms = md5(this.regReq.smsCode);
                if (md5Sms !== this.smsCode) {
                    console.log(md5Sms)
                    console.log(this.smsCode)
                    Message.error("短信验证码错误");
                    return;
                }
                let rst = await api().url("/reg").post({
                    regPhone: this.regReq.regPhone,
                    smsCode: this.regReq.smsCode,
                    regPwd: md5(this.regReq.regPwd),
                    repeatPwd: md5(this.regReq.repeatPwd),
                });
                if (rst.data.state) {
                    Message.success(rst.data.msg)
                } else {
                    Message.error(rst.data.msg)
                }
            } catch (e) {
                Message.error("系统错误,请稍后重试");
            }
        },
        // 发送验证码
        async sendSms() {
            try {
                if (this.regReq.regPhone.length !== 11) {
                    Message.error("非法手机格式");
                }
                let rst = await api().url("/sms").post({phone: this.regReq.regPhone});
                if (rst.data.state) {
                    this.smsCode = rst.data.code;
                    Message.success("验证码已发送成功");
                } else {
                    Message.error("验证码发送失败");
                }
            } catch (e) {
                Message.error("系统错误,请稍后重试");
            }
        },
        async loginByCode() {

        },
        async callbackByCode() {}
    },
})
