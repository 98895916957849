<template>
  <div
    class="item-card pointer"
    @click.stop="selectArticle"
    :class="
      articleListStore.curArticle !== null &&
      articleListStore.curArticle.id === props.item.id
        ? 'active'
        : ''
    "
  >
    <div class="item-card-sub">
      <div class="item-image" v-if="item['cover']">
        <a-image
          :preview="false"
          fit="cover"
          :width="64"
          :height="52"
          :src="item['cover']"
        ></a-image>
      </div>
      <div class="item-text" :style="item['cover'] ? '' : 'width:98%'">
        <div class="sub-title-con">
          <span class="sub-title two-line">{{ item["title"] }}</span>
        </div>

        <div class="sub-tips-con">
          <span class="sub-tips-second">{{ createAt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useArticleListStore } from "@/store/articleList";
import { parseDate } from "@/utils/helper";

const articleListStore = useArticleListStore();
const props = defineProps({
  item: Object,
});

const createAt = computed(() => {
  return parseDate(props.item.exportAt);
  //return dayjs(props.item.exportAt).format('YYYY-MM-DD').toString()
});
const selectArticle = () => {
  articleListStore.curDetail(props.item);
};
</script>

<style scoped>
.item-card {
  width: 224px;
  height: 62px;
  box-sizing: border-box;
  padding: 6px 2px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 3px solid rgba(255, 255, 255, 0.33);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 9px 0;
  position: relative;
}
.item-card-sub {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
}
.item-image {
  width: 64px;
  height: 52px;
  background: #ffffff;
  border-radius: 6px;
  overflow: hidden;
}
.item-text {
  width: 148px;
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 4px 0;
  margin-left: 8px;
}
.sub-title-con {
  width: 100%;
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}
.sub-title {
  display: inline-block;
  width: 98%;
  line-height: 17px;
  text-align: left;
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  font-weight: 500;
}
.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sub-tips-con {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sub-tips {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}
.sub-tips-second {
  color: rgba(0, 0, 0, 0.33);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.active {
  width: 230px;
  height: 68px;
  padding: 4px;
  border: 3px solid #81848a;
}
.active::after {
  content: "";
  position: absolute;
  right: -12px;
  width: 4px;
  height: 60px;
  top: 1px;
  bottom: 0;
  background: linear-gradient(0deg, #f4b543 0%, #f4b543 100%), #5ea2ff;
  border-radius: 20px;
}
.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
