<template>
  <div class="right-footer-tools" id="toolbar">
    <div
      class="option-btns"
      v-show="ES.activeId.value"
      @mousedown.prevent="() => {}"
    >
      <div class="group-icon">
        <div class="icon-sub pointer" @click="dispatch('undo')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            src="/tools_bar/undo.png"
            fit="contain"
          />
        </div>
        <div class="icon-sub sub-center pointer" @click="dispatch('redo')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            src="/tools_bar/redo.png"
            fit="contain"
          />
        </div>
      </div>
      <a-popover content-class="popover-content" :arrow-style="{ visibility: 'hidden' }">
        <div class="group-text">
          <!-- <div class="text-sub pointer" :style="{ background: activebg }"> -->
          <div class="text-sub pointer" >
            <span class="text-sub-text" :style="`font-size:${format.size}`">{{
              ES.getFontSizeToItem(format.size).text
            }}</span>
          </div>
        </div>
        <template #content>
          <div
            class="group-text"
            :style="{
              background: item.fs == format.size ? activebg : '',
            }"
            v-for="(item, index) of ES.fontSizeList"
            :key="index"
            @mousedown.prevent="() => {}"
          >
            <div class="text-sub pointer" @click="dispatch('size', item.fs)">
              <span class="text-sub-text" :style="`font-size:${item.fs}`">{{
                item.text
              }}</span>
            </div>
          </div>
        </template>
      </a-popover>
      <a-popover content-class="popover-content" :arrow-style="{ visibility: 'hidden' }">
        <!-- <div class="group-color" :style="{ background: activebg }"> -->
        <div class="group-color">
          <div
            class="color-sub"
            :style="{ background: format.color || ES.getDefaultStyle('color') }"
          ></div>
        </div>
        <template #content>
          <div
            class="group-color"
            v-for="item of ES.colorList"
            :key="item"
            :style="{
              background:
                format.color == item.toLocaleLowerCase() ? activebg : '',
            }"
            @click.stop="dispatch('color', item)"
            @mousedown.prevent="() => {}"
          >
            <div class="color-sub" :style="`background:${item}`"></div>
          </div>
        </template>
      </a-popover>
      <div class="group-icon group-center">
        <div class="icon-sub pointer" @click.stop="dispatch('bold')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            :src="format.bold ? '/tools_bar/b_1.png' : '/tools_bar/b.png'"
            fit="contain"
          ></a-image>
        </div>
        <div class="icon-sub sub-center pointer" @click="dispatch('italic')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            :src="format.italic ? '/tools_bar/i_1.png' : '/tools_bar/i.png'"
            fit="contain"
          ></a-image>
        </div>
        <div class="icon-sub pointer" @click="dispatch('underline')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            :src="format.underline ? '/tools_bar/u_1.png' : '/tools_bar/u.png'"
            fit="contain"
          ></a-image>
        </div>
      </div>
      <a-popover content-class="popover-content" :arrow-style="{ visibility: 'hidden' }">
        <div class="group-icon">
          <div class="icon-sub pointer">
            <a-image
              :preview="false"
              :width="36"
              :height="32"
              :src="`/tools_bar/${
                format.align == 'center' ? 5 : format.align == 'right' ? 4 : 3
              }_1.png`"
              fit="contain"
            ></a-image>
          </div>
        </div>
        <template #content>
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('align')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/3${!format.align ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('align', 'center')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/5${format.align == 'center' ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('align', 'right')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/4${format.align == 'right' ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
        </template>
      </a-popover>
      <a-popover content-class="popover-content" :arrow-style="{ visibility: 'hidden' }">
        <div class="group-icon">
          <div class="icon-sub pointer">
            <!-- <a-image
              :preview="false"
              :width="36"
              :height="32"
              :src="`/tools_bar/${
                format.list == 'bullet' ? 9 : format.list == 'ordered' ? 8 : 0
              }_1.png`"
              fit="contain"
            ></a-image> -->
            <a-image
              :preview="false"
              :width="36"
              :height="32"
              :src="`/tools_bar/${
                format.list == 'bullet' ? 9 : format.list == 'ordered' ? 8 : 0
              }.png`"
              fit="contain"
            ></a-image>
          </div>
        </div>
        <template #content>
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('list')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/0${!format.list ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('list', 'bullet')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/9${format.list == 'bullet' ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
          <!-- <div class="group-icon">
            <div class="icon-sub pointer" >
              <a-image :preview="false" :width="36" :height="32" src="/tools_bar/7.png" fit="contain"></a-image>
            </div>
          </div> -->
          <div class="group-icon">
            <div
              class="icon-sub pointer"
              @click="dispatch('list', 'ordered')"
              @mousedown.prevent="() => {}"
            >
              <a-image
                :preview="false"
                :width="36"
                :height="32"
                :src="`/tools_bar/8${format.list == 'ordered' ? '_1' : ''}.png`"
                fit="contain"
              ></a-image>
            </div>
          </div>
        </template>
      </a-popover>
      <div class="group-icon">
        <div class="icon-sub pointer" @click="dispatch('indent', '+1')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            :src="`/tools_bar/2${format.indent ? '_1' : ''}.png`"
            fit="contain"
          ></a-image>
        </div>
        <div class="icon-sub pointer" @click="dispatch('indent', '-1')">
          <a-image
            :preview="false"
            :width="36"
            :height="32"
            src="/tools_bar/1.png"
            fit="contain"
          ></a-image>
        </div>
      </div>
    </div>
    <div class="option-btns no-select">无段落被选择</div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { ES } from "./editor";

const activebg = "rgba(0, 0, 0, 0.15)";

const format = ref({});

const dispatch = (name, value) => {
  const quill = ES.getIns(ES.activeId.value);
  if (quill) {
    ES.dispatch(name, value);
    format.value = quill.getFormat();
    console.log(format.value);
  }
};

watch(ES.activeId, (id) => {
  const quill = ES.getIns(id);

  if (quill) {
    const { color, size } = ES.getDefaultStyle();
    setTimeout(() => {
      dispatch("color", color);
      dispatch("size", size);
    });
    quill.on("editor-change", function () {
      format.value = quill.getFormat();
      console.log(format.value);
    });
  }
});
</script>

<style scoped>
.right-footer-tools {
  width: 700px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background:#fafafa;
  box-shadow: -10px -19px 100px 50px rgba(245, 244, 245, 0.66);
  z-index: 10;
}

.group-center {
  margin: 0 8px;
}

.group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
}

.text-sub {
  height: 32px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 1px;
  padding: 5px 6px;
}

.text-sub-active {
  background: #ffffff;
}

.sub-center {
  /* margin:0 6px; */
}

.text-sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #222222;
  box-sizing: border-box;
}

.text-active-color {
  color: #9b9b9b;
}

.text-active-blue {
  color: #2079f2;
}

.group-color {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 6px;
}

.group-color > .color-sub {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  /* background-color: red; */
  flex-shrink: 0;
}

.group-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
}

.icon-sub {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 1px;
  overflow: hidden;
}

.icon-active {
  background: #ffffff;
}

.option-btns {
  width: 656px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 0 10px 20px;
}

.no-select {
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;
  z-index: 9999;
}
</style>

<style>
.popover-content {
  line-height: normal;
  padding: 0px !important;
  margin-bottom: -6px;
}

.popover-content > .arco-popover-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  margin: 0;
}
</style>
