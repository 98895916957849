<template>
  <div class="header-tool">
    <div class="header-left">
      <img src="@/assets/article/back.png" class="back-icon pointer" @click.stop="backBtn"/>
      <span class="header-title">编写文章</span>
      <!-- <div class="do-con">
        <a-image :preview="false" :width="22" :height="22" src="/undo.png" fit="contain" class="icon pointer" @click.stop="undoBtn"></a-image>
        <a-image :preview="false" :width="22" :height="22" src="/redo.png" fit="contain" class="icon pointer" @click.stop="redoBtn"></a-image>
      </div> -->
    </div>
    <div class="btn pointer" @click.stop="saveBtn">编辑完成</div>
  </div>
</template>

<script setup>
  import { onMounted} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
  import router from "@/router/index";

  const articleEditStore=useArticleEditStore();

  onMounted(() => {  });
  const backBtn=()=>{
    router.back();
  }
  // const undoBtn=()=>{
  //   articleEditStore.undo();
  // }
  // const redoBtn=()=>{
  //   articleEditStore.redo();
  // }
  const saveBtn=async()=>{
    await articleEditStore.submit();
  }
</script>

<style scoped>
.header-tool{
  height: 52px;
  background: #444751;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.header-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.do-con{
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 15px ;
  width: 104px;
  box-sizing: border-box;
}
.icon{
  width: 22px;
  height: 22px;
  color: #333333;
}
.btn{
  padding: 6px 11px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  background: #F4B543;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.header-title{
  width: 106px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.back-icon{
  width: 16px;
  height: 16px;
}
</style>
