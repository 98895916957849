<template>
  <div class="con">
    <div class="title-con">
      <span class="title">{{item['title']}}</span>
    </div>
    <div class="tips-con">
      <span class="tips">{{createAt}}</span>
<!--      <span class="tips second">作者昵称</span>-->
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, computed} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
import dayjs from "dayjs";

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  })
const createAt=computed(()=>{
  return dayjs(props.item.exportAt).format('YYYY-MM-DD').toString()
})
  onMounted(() => {  });

</script>

<style scoped>
.con{
  width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
}
.title-con{
  text-align: left;
}

.title{
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #333333;

}
.tips-con{
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.tips{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #9A9A9A;
}
.second{
  margin-left: 14px;
}
</style>

