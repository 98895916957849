import dayjs from "dayjs";

var COS = require('cos-js-sdk-v5');
import {bucketConfig, cheeryCosConfig} from "./config.js";

function returnParser(result){
	let rst={};
	rst.statusCode=result.status;
	rst.msg=result.data.msg;
	rst.data=result.data.result;
	return rst;
}

function timeFormat(timestamp){
	let date=new Date(timestamp*1000);
	let year = date.getFullYear();
	let month = date.getMonth()+1;
	let day = date.getDate();
	return year + '-' + month + '-' + day;
}

function putObj(articleId,file,success=null,fail=null){
	let cos=new COS(cheeryCosConfig);
	let filename=new Date().getTime()+'_'+file.name;
	// let url=part.url;
	// let artId=part.articleId;
	let key='/'+articleId+'/'+filename;

	if(file){
		cos.putObject({
			Bucket: bucketConfig.Bucket,
			Region: bucketConfig.Region,
			Key: key,
			Body: file,
		}, function (err,data) {
			if(err){
				if(fail){fail(err);}
			}else if(data.statusCode!= 200){
				if(fail){fail(err);}
			}else{
				if(success){success(data.Location)}
			}
		});
	}
}
function createObj(tp,artId) {
	let item = {};
	item.articleId = artId;
	item.content = null;
	item.createTime = null;
	item.orders = null;
	item.partId = null;
	item.style = null;
	item.tips = null;
	item.type = null;
	item.updateTime = null;
	item.url = null;
	if (tp == "text") {
	  item.type = 0;
	  item.content = null;
	  item.style = "globalLeft";
	}
	if (tp == "image") {
	  item.type = 1;
	  item.tips = null;
	  item.style = "globalCenter";
	}
	if (tp == "video") {
	  item.type = 2;
	  item.tips = null;
	  item.style = "globalCenter";
	}
	return item;
  }

  //获取16位uuid
  function getUUID() {
	return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
	  const r = (Math.random() * 16) | 0;
	  const v = c === "x" ? r : (r & 0x3) | 0x8;
	  return v.toString(16);
	});
  };

 /**
  * 解析日期
  * 小于等于15分钟=“刚刚”
  * 大于15分钟小于60分钟=“1小时前”
  * 大于60分钟小于180分钟=“3小时前”
  * 大于180分钟，日期是今天的=“今天”
  * 日期是昨天的=“昨天”
  * 再往前就直接显示日期了
  * */
 function parseDate(dateStr){
	 let date=new Date(dateStr);
    let curDate=new Date();
	let cur=curDate.getTime();
	let target=date.getTime();
	let plus=(cur-target)/1000;
	let str=dayjs(date).format('YYYY-MM-DD').toString()
    let pre_day=new Date(cur-24*60*60*1000);

	if(plus<=15*60){
		str="刚刚";
	}else if(plus<=60*60){
		str="1小时前";
	}else if(plus<=3*60*60){
		str="3小时前";
	}else{
		if(curDate.getDate() === date.getDate()){
			str="今天"
		}else if(pre_day.getDate() === date.getDate()){
			str="昨天"
		}
	}
	 return str;
 }
export {
	returnParser,
	timeFormat,
	putObj,
	createObj,
	getUUID,
	parseDate
}
